@font-face {
  font-family: 'Signature';
  src: local('Signature'), url(./fonts/MrDafoe-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenier Light';
  src: local('Avenier Light'), url(./fonts/Avenir-Light.ttf) format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Avenier Light";
  font-size: 15px;
  letter-spacing: .5px;
  font-weight: 400;
  background-color: #fff;
  /* background:repeating-linear-gradient(to left,#F9F9F9, #F9F9F9 49.9%, #F9F9F9 50.1%, #F9F9F9 100%) !important; */
}

#tsparticles {
  height: 100vh !important;
}

.toggle.active {
  /* background: url(https://i.ibb.co/rt3HybH/close.png);
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
  cursor: pointer; */
  /* background: #18130E; */
}

.topbar {
  background: #18130E;
}

#myNav {
  width: 100%;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: '10px';
}

/* Handle */

::-webkit-scrollbar-thumb {
  position: relative;
  top: 0px;
  /* float: right; */
  /* width: 6px; */
  height: auto;
  background-color: #18130E;
  border: 1px solid rgb(255, 255, 255);
  /* background-clip: padding-box; */
  border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #18130E;
}

.pre-loader {
  top: 44%;
  position: absolute;
  left: 45%;
  -webkit-animation: preloader 3s infinite ease-in-out;
  animation: preloader 3s infinite ease-in-out;
}

@media (max-width:767px) {
  .pre-loader {
    top: 54% !important;
    left: 34% !important;
  }
  .pre-loader-text {
    top: 70% !important;
    left: 32% !important;
  }
}

.pre-loader-text {
  font-family: 'Signature';
  position: absolute;
  top: 63%;
  left: 42%;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
}

@-webkit-keyframes preloader {
  60% {
    -webkit-transform: translate(0px, -100px) scale(1.2);
    opacity: 0.9;
    text-shadow: 0px 150px 20px rgba(0, 0, 0, 0.8);
  }
}

@keyframes preloader {
  60% {
    -webkit-transform: translate(0px, -100px) scale(1.2);
    opacity: 0.9;
    text-shadow: 0px 150px 20px rgba(0, 0, 0, 0.8);
  }
}